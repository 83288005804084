import type { LayoutProps } from '../../types';
import * as React from 'react';
import styled from '@emotion/styled';
import Container from '@mui/material/Container';
import { LayoutAds } from './LayoutAds';

const Root = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignSelf: 'center',
  height: 'auto',
  justifyContent: 'center',
  maxWidth: '1200px',
  margin: '0 auto'
})) as typeof Container;

const SectionLeft = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start', // different
  alignItems: 'center',
  flex: 1, // different
  height: 'auto',
}));

export const LayoutA = (props: LayoutProps) => {
  const { children } = props;

  return (
    <Root>
      <SectionLeft>{children}</SectionLeft>
      <LayoutAds />
    </Root>
  );
};
