import React from 'react';
import styled from '@emotion/styled';
import Image, { ImageLoaderProps } from 'next/image';
import { useAppContext } from '../../contexts/AppContext';

export const FooterBarEmpty = () => {
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();

  const AzerionBrandContainer = styled('div')(() => ({
    backgroundColor: 'black',
    color: 'white',
  }));

  const AzerionBrandContent = styled('div')(() => ({
    backgroundColor: 'black',
    color: 'white',
    marginLeft: '30px',
    padding: '10px 0',
  }));

  const azerionBrand = componentOptions?.component_footer_bar_azerion_footer;

  return (
    <>
      {azerionBrand && (
        <AzerionBrandContainer>
          <AzerionBrandContent>
          <Image
            src="/images/azerion-brand.png"
            loader={({ src, width }: ImageLoaderProps) => `${src}?w=${width || 180}`}
            alt="made-with-love"
            height="30"
            width="180"
          />
          </AzerionBrandContent>
        </AzerionBrandContainer>
      )}
    </>
  );
};
