export const DEVELOPMENT_USER_AVATAR_LIST = [
  'e7f150de-693c-40f8-b99f-6f391497924f',
  '54df2df6-6893-44ff-bd59-dc5f069d4966',
  '0cda05de-d9c7-4559-972a-2b254eb5edeb',
  'e3853542-29d3-4067-ab73-6f43e42ba0f6',
  '968ff027-b6e6-4be7-92fc-0028916d76e8',
  '072fadf5-62ff-4e8a-86ae-f3d4d5cb5122',
];

export const PRODUCTION_USER_AVATAR_LIST = [
  'f7ffed9a-12d6-4483-94d1-4d9976d69529',
  '3d89d0c3-72c7-4e8e-91d2-91be99c5f642',
  'ba16bc1b-45fd-4af5-a534-05138ab48735',
  'e96a3df4-1589-45cc-b440-f1320faef777',
  '2ff729ff-9341-4803-bb15-595a69bcab0a',
  '552bcaf1-7302-4771-a2ac-97971f665842',
];

export const LAYOUTADSCONFIGRATION_AC = [
  {
    name: 'component_layout_right_ads_disabled',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_tablet_ads_disabled',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_ads_mobile_disabled',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const LAYOUTADSCONFIGRATION_AC_BOTTOM = [
  {
    name: 'component_layout_right_middle_ads_disabled',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_middle_tablet_ads_disabled',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_middle_mobile_ads_disabled',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const LAYOUTADSCONFIGRATION_B = [
  {
    name: 'component_layout_right_ads_disabled',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_ads_tablet_disabled',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_layout_right_ads_mobile_disabled',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const COLLECTIONS_B_TOP = [
  {
    name: 'component_right_ads_disable',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_right_ads_tablet_disable',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_right_ads_mobile_disable',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const COLLECTIONS_B_MIDDLE = [
  {
    name: 'component_middle_center_ads_disable',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_middle_center_ads_tablet_disable',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_middle_center_ads_mobile_disable',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

export const COLLECTIONS_B_BOTTOM = [
  {
    name: 'component_bottom_ads_disable',
    label: 'Close the desktop ad space.',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_bottom_ads_tablet_disable',
    label: 'Close the tablet ad space"',
    type: 'checkbox',
    default: false,
  },
  {
    name: 'component_bottom_ads_mobile_disable',
    label: 'Close the mobile ad space."',
    type: 'checkbox',
    default: false,
  },
];

