import type { BoxProps } from '@mui/material/Box/Box';

import * as React from 'react';
import { useEditorHandlers } from './@editor';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.theme_component_collection_border_radius
    ? `${theme.theme_component_collection_border_radius}px`
    : theme.shape.borderRadius,
  backgroundColor: theme.theme_component_collection_background_color || 'rgba(0, 0, 0, 0)',
  borderBottomWidth: theme.theme_component_collection_border_bottom_width
    ? `${theme.theme_component_collection_border_bottom_width}px`
    : 0,
  borderBottomColor: theme.theme_component_collection_border_bottom_color || theme.palette.divider,
  borderBottomStyle: 'solid',
  '&:first-of-type': {
    marginTop: theme.spacing(0),
  },
  '&:last-of-type:not(:first-of-type)': {
    marginBottom: theme.spacing(0),
  },
})) as typeof Box;

export const CollectionSection = (props: BoxProps) => {
  const { children, ...other } = props;
  return (
    <Root {...useEditorHandlers('Collection Section')} {...other}>
      {children}
    </Root>
  );
};
