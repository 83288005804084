import type { BaseLayoutProps } from '../../types';

import * as React from 'react';
import dynamic from 'next/dynamic';
import { DefaultLayout } from '../DefaultLayout';

// IMPORTANT: The page.name in the import and switch case must match with the pageType set in the BaseLayoutProps definition!
import { Layout as LayoutIndex } from '../../generated/index/layout';
import { Layout as LayoutGame } from '../../generated/game/layout';
import { Layout as LayoutSearch } from '../../generated/search/layout';
import { Layout as LayoutCategory } from '../../generated/category/layout';
import { Layout as LayoutCategories } from '../../generated/categories/layout';
import { Layout as LayoutCollection } from '../../generated/collection/layout';
import { Layout as LayoutCollections } from '../../generated/collections/layout';
const NavigationBar = dynamic<any>(() => import('../../components/NavigationBarEmpty').then((mod) => mod.NavigationBarEmpty), { ssr: true });
const FooterBar = dynamic<any>(() => import('../../components/FooterBarEmpty').then((mod) => mod.FooterBarEmpty), { ssr: true });

export function BaseLayout(props: BaseLayoutProps) {
  const { children, pageType, pageContext } = props;
  return (
    <>
      <NavigationBar/>
      {(() => {
        switch (pageType) {
          case 'index':
            return <LayoutIndex pageContext={pageContext}>{children}</LayoutIndex>;
          case 'game':
            return <LayoutGame pageContext={pageContext}>{children}</LayoutGame>;
          case 'search':
            return <LayoutSearch pageContext={pageContext}>{children}</LayoutSearch>;
          case 'category':
            return <LayoutCategory pageContext={pageContext}>{children}</LayoutCategory>;
          case 'categories':
            return <LayoutCategories pageContext={pageContext}>{children}</LayoutCategories>;
          case 'collection':
            return <LayoutCollection pageContext={pageContext}>{children}</LayoutCollection>;
          case 'collections':
            return <LayoutCollections pageContext={pageContext}>{children}</LayoutCollections>;
          default:
            return <DefaultLayout pageContext={pageContext}>{children}</DefaultLayout>;
        }
      })()}
      <FooterBar/>
    </>
  );
}
