import type { CategoriesViewProps, CategoryProps, GameProps } from '../../types';
import { CardTypeEnum } from '../../types/enum';

import * as React from 'react';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../contexts/AppContext';
import Container from '@mui/material/Container';
import { Title } from '../../components/Title';
import { Link } from '../../components/Link';
import { CollectionSection } from '../../components/CollectionSection';
import { Divider, useMediaQuery } from '@mui/material';
import useLocalStorage from '../../utils/use-local-storage';
import useFlattenedGameData from '../../utils/useFlattenArray';

const CarouselList = dynamic<any>(() => import('../../components/CarouselList').then((mod) => mod.CarouselList), {
  ssr: false,
});

export const CollectionsD = (props: CategoriesViewProps) => {
  const { collections } = props;
  const theme = useTheme();
  const { t } = useTranslation('collections_d');
  const { ref } = useInView();
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();

  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const cardType = componentOptions?.component_game_carousel_card_type;

  const [lastPlayedGames] = useLocalStorage<GameProps[] | undefined>('lastplayed:current', []);
  const showLastPlayedCarousel = componentOptions?.component_homepage_show_last_played_carousel;

  const recommendedCollection = collections.find((c) => c.code.includes('recommended')) || { games: [] };
  
  const flattenedData = useFlattenedGameData(lastPlayedGames || []);

  return (
    <Container ref={ref} disableGutters={!(md || sm)} sx={{ maxWidth: '100vw' }}>
      {showLastPlayedCarousel && flattenedData && flattenedData.length > 0 && (
        <CollectionSection key="my-last-played">
          <Title component={'h1'} variant={'h1'} align="left">
            <Link href="/" title={t('my_last_played', 'My Last Played') || undefined} underline="hover">
              {t('my_last_played', 'My Last Played')}
            </Link>
            <Link href="/" title={t('view_all_label', 'View all')} underline="hover">
              {t('view_all_label', 'View all')}
            </Link>
          </Title>
          <CarouselList
            padding={theme.spacing(2)}
            items={[
              ...flattenedData,
              ...recommendedCollection.games.map((game) => ({
                ...game,
                trend: true,
              })),
            ]}
            slidesPerView={4}
            breakpoints={{
              900: {
                // md
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
              600: {
                // sm
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              0: {
                // xs
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
            }}
            cardOptions={{
              width: componentOptions.component_card_width || 512,
              height: componentOptions.component_card_height || 288,
              layout: 'responsive',
              sizes: '(min-width:1200px) 10vw, (min-width:900px) 25vw, (min-width:600px) 33.333vw, 50vw',
              template: cardType || CardTypeEnum.EXTENSIVE,
            }}
            typographyOptions={{
              align: 'center',
            }}
          />
        </CollectionSection>
      )}
      {collections &&
        collections.map((collection: CategoryProps, index: number) => (
          <>
            <Divider sx={{ marginTop: theme.spacing(2), border: 'none' }} />
            <CollectionSection key={collection.slug}>
              <Title component={index === 0 ? 'h1' : 'h2'} variant={index === 0 ? 'h1' : 'h2'} align="left">
                <Link
                  href={`/${collection?.type}/${collection?.slug}`}
                  title={collection?.name || undefined}
                  underline="hover"
                >
                  {collection.name}
                </Link>
                <Link
                  href={`/${collection?.type}/${collection?.slug}`}
                  title={collection?.name || undefined}
                  underline="hover"
                >
                  {t('view_all_label', 'View all')}
                </Link>
              </Title>
              <CarouselList
                padding={theme.spacing(2)}
                items={collection?.games || []}
                slidesPerView={4}
                breakpoints={{
                  900: {
                    // md
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                  },
                  600: {
                    // sm
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                  },
                  0: {
                    // xs
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                }}
                cardOptions={{
                  width: componentOptions.component_card_width || 512,
                  height: componentOptions.component_card_height || 288,
                  layout: 'responsive',
                  sizes: '(min-width:1200px) 10vw, (min-width:900px) 25vw, (min-width:600px) 33.333vw, 50vw',
                  template: cardType || CardTypeEnum.EXTENSIVE,
                }}
                typographyOptions={{
                  align: 'center',
                }}
              />
            </CollectionSection>
          </>
        ))}
    </Container>
  );
};
