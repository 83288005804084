import { useCursorHandlers } from '../../../contexts/CursorContext/Cursor';
import { useTheme } from '@mui/material/styles';

export const useEditorHandlers = (name: string) => {
  const theme = useTheme();

  return useCursorHandlers({
    name,
    styles: [
      {
        name: 'theme_component_collection_border_radius',
        label: 'Border radius',
        type: 'number',
        endAdornment: 'px',
        default: theme.shape.borderRadius,
      },
      {
        name: 'theme_component_collection_background_color',
        label: 'Background color',
        type: 'color',
        default: 'rgba(0, 0, 0, 0)',
      },
      {
        name: 'theme_component_collection_border_bottom_width',
        label: 'Border bottom width',
        type: 'number',
        default: 0,
        endAdornment: 'px',
      },
      {
        name: 'theme_component_collection_border_bottom_color',
        label: 'Border bottom color',
        type: 'color',
        default: theme.palette.divider,
      },
    ],
    configuration: [],
  });
}